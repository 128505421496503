import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import LinkComponent from "../../../utils/LinkComponent";

import logo from "../../../assets/images/logo.png";

class LoginPage extends React.Component {
  render() {
    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className="full-height-page"
      >
        <Grid item>
          <Paper className="login-page-paper">
            <Grid
              container
              spacing={32}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <img src={logo} alt="logo" />
              </Grid>
              <Grid item>
                <Typography align="center" variant="body2" color="textPrimary">
                  This website uses Azure Active Directory authentication.
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center" variant="body2" color="textPrimary">
                  Pressing "LOG IN" button below will open a pop-up with
                  Microsoft authentication page.
                </Typography>
              </Grid>
              <Grid item>
                <LinkComponent to="/login">
                  <Button variant="contained" color="primary">
                    Log in
                  </Button>
                </LinkComponent>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default LoginPage;
