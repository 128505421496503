import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "./../reducers";
import { watchAll } from "../sagas";

export const history = createBrowserHistory();

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const reducer = createRootReducer(history);

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), sagaMiddleware)
);

export const configureStore = () => {
  const store = createStore(reducer, enhancer);

  // run sagas only after store is created
  sagaMiddleware.run(watchAll);

  return store;
};
