import * as actions from "../actions/actionTypes";
import { put, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import client from "../config/apolloConfig";

import {
  transformCreateProject,
  transformUpdateProject,
  transformEnumsToBooleans
} from "../utils/transformations";
import {
  ROUTE_TO_DASHBOARD,
  FILE_SIZE_TOO_LARGE_MESSAGE,
  DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
  UNABLE_TO_FIND_PROJECT_MESSAGE,
  DEFAULT_PAGES_COUNT
} from "../utils/constants";
import { getNestedObject, determineFileDocumentType } from "../utils/functions";
import { errorHandler } from "../utils/errorHandler";

import { generateDefaultConsultant } from "../store/storeFunctions";

import { GetUploadResourcePolicy } from "../graphql/mutations/GetUploadResourcePolicy";
import { CreateProjectMutation } from "../graphql/mutations/CreateProjectMutation";
import { UpdateProjectMutation } from "../graphql/mutations/UpdateProjectMutation";

import { GetProjectsQuery } from "../graphql/queries/GetProjectsQuery";
import { GetProjectQuery } from "../graphql/queries/GetProjectQuery";
import { adUserSearch } from "../graphql/queries/adUserSearchQuery";

export function* submitProjectOrChangesSaga(action) {
  try {
    let variables = { ...(yield select(state => state.project.form)) };

    if (variables.id === "") {
      /* NEW PROJECT */
      variables = yield transformCreateProject(variables);

      yield call(() =>
        client.mutate({ mutation: CreateProjectMutation, variables })
      );

      yield put({
        type: actions.SUBMIT_OR_UPDATE_PROJECT_SAGA_SUCCESS,
        payload: {}
      });
      yield put({
        type: actions.OPEN_GLOBAL_SNACKBAR,
        payload: { message: "Successfully submitted new project" }
      });
    } else {
      /* EXISTING PROJECT UPDATE */
      variables = yield transformUpdateProject(variables);

      yield call(() =>
        client.mutate({ mutation: UpdateProjectMutation, variables })
      );

      yield put({
        type: actions.SUBMIT_OR_UPDATE_PROJECT_SAGA_SUCCESS,
        payload: {}
      });
      yield put({
        type: actions.OPEN_GLOBAL_SNACKBAR,
        payload: { message: "Successfully updated project" }
      });
    }

    // route us to dashboard either way
    yield put(push(ROUTE_TO_DASHBOARD));
  } catch (err) {
    // yield put({
    //   type: actions.SUBMIT_OR_UPDATE_PROJECT_SAGA_FAILURE,
    //   payload: {}
    // });
    yield put({
      type: actions.OPEN_GLOBAL_SNACKBAR,
      payload: { message: "Failed to submit or update a project" }
    });
  }
}

export function* fetchProjectsSaga(action) {
  try {
    const sorted = getNestedObject(action, ["payload", "sorted", 0]);
    let orderBy = null;
    if (sorted) {
      orderBy = sorted.id;
      orderBy += sorted.desc ? "_DESC" : "_ASC";
    }

    let where = null;
    getNestedObject(action, ["payload", "filtered"]).forEach(filter => {
      switch (filter.id) {
        case "creatorName":
          where = {
            ...where,
            creator: { nameLowercase_contains: filter.value.toLowerCase() }
          };
          break;
        case "clientName":
          where = {
            ...where,
            clientNameLowercase_contains: filter.value.toLowerCase()
          };
          break;
        case "projectName":
          where = {
            ...where,
            projectNameLowercase_contains: filter.value.toLowerCase()
          };
          break;
        case "projectDatesStart":
          if (filter.value) {
            where = { ...where, projectDatesStart_gte: filter.value };
          }
          break;
        case "projectDatesEnd":
          if (filter.value) {
            where = { ...where, projectDatesEnd_lte: filter.value };
          }
          break;
        default:
          where = { ...where, [filter.id + "_contains"]: filter.value };
      }
    });

    let variables = null;
    variables = {
      skip: action.payload.page * action.payload.pageSize,
      first: action.payload.pageSize,
      ...(where ? { where } : null),
      ...(orderBy ? { orderBy } : null)
    };

    const result = yield call(() =>
      client.query({
        query: GetProjectsQuery,
        variables,
        fetchPolicy: "no-cache"
      })
    );

    // extract projects
    const projects = getNestedObject(result, [
      "data",
      "projectsConnection",
      "edges"
    ]).map(edge => {
      return edge.node;
    });

    //extract count
    const count = getNestedObject(result, [
      "data",
      "count",
      "aggregate",
      "count"
    ]);

    const projectsPageCount = count
      ? Math.ceil(count / action.payload.pageSize)
      : DEFAULT_PAGES_COUNT;

    yield put({
      type: actions.FETCH_PROJECTS_SAGA_SUCCESS,
      payload: { data: projects, projectsPageCount }
    });
  } catch (err) {
    const [openSnackbar, messageSnackbar, redirect, redirectTo] = errorHandler(
      err
    );
    yield openSnackbar &&
      put({
        type: actions.OPEN_GLOBAL_SNACKBAR,
        payload: { message: messageSnackbar }
      });
    yield redirect && put(push(redirectTo));

    yield put({ type: actions.FETCH_PROJECTS_SAGA_FAILURE, payload: { err } });
  }
}

export function* fetchProjectSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetProjectQuery,
        variables: { id: action.payload.id },
        fetchPolicy: "no-cache"
      })
    );

    const data = yield getNestedObject(result, ["data", "project"]) || null;

    // in-place transform YES/NO to true/false
    yield transformEnumsToBooleans(data);

    if (!data) {
      throw UNABLE_TO_FIND_PROJECT_MESSAGE;
    }

    // if there are no consultants on this project yet,
    // prepare input for one
    if (data && (!data.consultants || !data.consultants.length)) {
      data.consultants = yield [generateDefaultConsultant()];
    }

    yield put({
      type: actions.FETCH_PROJECT_SAGA_SUCCESS,
      payload: { data: data }
    });
  } catch (err) {
    yield put(push(ROUTE_TO_DASHBOARD));
    yield put({ type: actions.FETCH_PROJECT_SAGA_FAILURE, payload: { err } });
    yield put({
      type: actions.OPEN_GLOBAL_SNACKBAR,
      payload: {
        message:
          typeof err === "string"
            ? err
            : "Something went wrong while fetching project"
      }
    });
  }
}

export function* discardProjectOrChangesSaga(action) {
  yield put({ type: actions.CLEAN_PROJECT_FORM, payload: null });
  yield put(push(ROUTE_TO_DASHBOARD));
}

export function* uploadFileSaga(action) {
  try {
    /* get resource policy */
    const resourcePolicy = yield call(() =>
      client.mutate({
        mutation: GetUploadResourcePolicy,
        variables: { uploadType: action.payload.uploadType }
      })
    );

    const stringifiedJson = getNestedObject(resourcePolicy, [
      "data",
      "getAWSResourcePolicyForUpload",
      "json"
    ]);

    const parsedJson = stringifiedJson && JSON.parse(stringifiedJson);

    const file = action.payload.file;

    /* basic file validation */
    if (file.size > parsedJson.maxFileSize) {
      throw FILE_SIZE_TOO_LARGE_MESSAGE;
    }

    /* upload to S3 */
    const data = new FormData();
    data.append("key", parsedJson.fields.key);
    data.append("x-amz-meta-tag", "this-is-a-tag");
    data.append("Content-Type", file.type);
    data.append("x-amz-server-side-encryption", "AES256");
    data.append("X-Amz-Credential", parsedJson.fields["X-Amz-Credential"]);
    data.append("X-Amz-Algorithm", parsedJson.fields["X-Amz-Algorithm"]);
    data.append("X-Amz-Date", parsedJson.fields["X-Amz-Date"]);
    data.append("Policy", parsedJson.fields.Policy);
    data.append("X-Amz-Signature", parsedJson.fields["X-Amz-Signature"]);
    data.append("success_action_status", "");
    // data.append("acl", "public-read");
    data.append("file", file);

    yield fetch(parsedJson.subdomainUrl, {
      mode: "cors",
      method: "POST",
      body: data
    })
      .then(res => {
        // console.log("res", res);

        // if status is not 204, it means upload failed
        if (res.status !== 204) {
          throw res.statusText;
        }
      })
      .catch(err => {
        throw err;
      });

    // if we're here upload was successful
    yield put({
      type: actions.UPLOAD_FILE_SAGA_SUCCESS,
      payload: {
        name: file.name,
        contentType: file.type,
        type: determineFileDocumentType(file.type),
        key: parsedJson.fields.key
      }
    });
  } catch (err) {
    yield put({ type: actions.UPLOAD_FILE_SAGA_FAILURE, payload: null });
    yield put({
      type: actions.OPEN_GLOBAL_SNACKBAR,
      payload: {
        message:
          typeof err === "string"
            ? err
            : "Something went wrong with file upload"
      }
    });
  }
}

export function* searchAdForUserSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: adUserSearch,
        variables: {
          contains: action.payload.value,
          limit: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT
        }
      })
    );

    /* adapt data to react-select expected object structure
     * as this will be used for displying available options to user
     */
    const data = getNestedObject(result, ["data", "adUserSearch"]) || null;

    yield put({ type: actions.SEARCH_AD_FOR_USER_SUCCESS, payload: { data } });
  } catch (err) {
    yield put({ type: actions.SEARCH_AD_FOR_USER_FAILURE, payload: null });
  }
}
