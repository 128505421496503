import moment from "moment";

export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

// use moment to format dates and parse them as UTC
export const formatDateISO8601 = date =>
  moment(moment.utc(date)).format("YYYY-MM-DD");
export const formatDateUS = date =>
  moment(moment.utc(date)).format("MM/DD/YYYY");

export const determineFileUploadType = fileType => {
  switch (fileType) {
    case "text/plain":
    case "text/csv":
    case "application/pdf":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
    case "application/epub+zip":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/rtf":
    case "application/vnd.visio":
    case "application/xml":
    case "text/xml":
      return "PROJECT_DOCUMENT";
    case "image/jpeg":
    case "image/png":
    case "image/x-icon":
    case "image/vnd.microsoft.icon":
    case "image/bmp":
    case "image/gif":
    case "image/webp":
      return "IMAGE";
    default:
      return "OTHER";
  }
};

export const determineFileDocumentType = fileType => {
  switch (fileType) {
    case "text/plain":
    case "text/csv":
    case "text/xml":
    case "application/xml":
    case "application/rtf":
    case "application/vnd.oasis.opendocument.text":
      return "TXT";
    case "application/pdf":
      return "PDF";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/vnd.oasis.opendocument.spreadsheet":
      return "EXCEL";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return "WORD";
    case "image/jpeg":
    case "image/png":
    case "image/x-icon":
    case "image/vnd.microsoft.icon":
    case "image/bmp":
    case "image/gif":
    case "image/webp":
      return "IMAGE";
    default:
      return "BLOB";
  }
};

// checks if the two arrays have different values in specified fields (id)
export const haveArraysDiverged = (
  currentArray,
  previousArray,
  fieldsToCheck
) => {
  /* Expects id and value keys in each array elements object
   * Only checks top-level data
   */

  if (!fieldsToCheck || fieldsToCheck.length === 0) {
    return false;
  }

  if (!currentArray || !previousArray) {
    return true;
  }

  const newArray = reduceIdValueArrayToObject(currentArray, fieldsToCheck);
  const oldArray = reduceIdValueArrayToObject(previousArray, fieldsToCheck);

  let hasDiverged = false;

  for (let key in newArray) {
    if (newArray[key] !== oldArray[key]) {
      hasDiverged = true;
      break;
    }
  }

  return hasDiverged;
};

export const reduceIdValueArrayToObject = (array, keysToAdd) =>
  array.reduce((acc, curr) => {
    if (keysToAdd.includes(curr.id)) {
      acc[curr.id] = curr.value;
    }
    return acc;
  }, {});
