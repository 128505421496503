import React from "react";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { formatDateUS } from "../../../../utils/functions";
import LinkComponent from "../../../../utils/LinkComponent";

import CustomDatePicker from "../../../../common/Pickers";

// set this on all columns,
// due to bug: https://github.com/tannerlinsley/react-table/issues/1272
// minResizeWidth: 10
export default [
  {
    show: true,
    id: "id",
    Header: "id",
    accessor: "id",
    minResizeWidth: 10
  },
  {
    show: true,
    filterable: false,
    sortable: false,
    id: "edit",
    Header: "Edit",
    minResizeWidth: 10,
    width: 60,
    Cell: row => {
      return (
        <LinkComponent to={`/project/${row.original.id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </LinkComponent>
      );
    }
  },
  {
    Header: "Client",
    columns: [
      {
        show: true,
        filterable: true,
        id: "clientName",
        Header: "Name",
        accessor: "clientName",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "clientAddress",
        Header: "Address",
        accessor: "clientAddress",
        minResizeWidth: 10
      }
    ]
  },
  {
    Header: "Project",
    columns: [
      {
        show: true,
        filterable: true,
        id: "projectName",
        Header: "Name",
        accessor: "projectName",
        minResizeWidth: 10
      },
      {
        show: true,
        filterable: true,
        id: "projectDatesStart",
        Header: "Start date",
        accessor: row => {
          return formatDateUS(row.projectDatesStart);
        },
        Filter: ({ filter, onChange }) => {
          return (
            <CustomDatePicker
              USFormat
              disableUnderline
              fullWidth
              clearable
              placeholder="From date..."
              defaultValue={filter ? filter.value : null}
              onChange={selectedDate => {
                onChange(selectedDate);
              }}
            />
          );
        },
        minResizeWidth: 10
      },
      {
        show: true,
        filterable: true,
        id: "projectDatesEnd",
        Header: "End date",
        accessor: row => {
          return formatDateUS(row.projectDatesEnd);
        },
        Filter: ({ filter, onChange }) => {
          return (
            <CustomDatePicker
              USFormat
              disableUnderline
              fullWidth
              clearable
              placeholder="...to date"
              defaultValue={filter ? filter.value : null}
              onChange={selectedDate => {
                onChange(selectedDate);
              }}
            />
          );
        },
        minResizeWidth: 10
      }
    ]
  },
  {
    Header: "Billing",
    columns: [
      {
        show: true,
        id: "invoiceTermsAndBillingInstructions",
        Header: "Invoice Terms & Billing Instructions",
        accessor: "invoiceTermsAndBillingInstructions",
        minResizeWidth: 10,
        width: 270
      },
      {
        show: true,
        id: "enableExpenses",
        Header: "Expenses",
        accessor: "enableExpenses",
        sortable: false,
        minResizeWidth: 10
      },
      {
        show: true,
        filterable: true,
        id: "projectValue",
        Header: "Project value",
        accessor: "projectValue",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "PONumber",
        Header: "PO number",
        accessor: "PONumber",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "billingContactName",
        Header: "Contact name",
        accessor: "billingContact.name",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "billingContactEmail",
        Header: "Contact email",
        accessor: "billingContact.email",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "billingContactPhone",
        Header: "Contact phone",
        accessor: "billingContact.phone",
        minResizeWidth: 10
      }
    ]
  },
  {
    Header: "Consultant type counts",
    columns: [
      {
        show: true,
        id: "consultantsTypeConsultant",
        Header: "Consultant",
        accessor: row => {
          return row.consultants.reduce((acc, cur) => {
            if (cur.type === "CONSULTANT") {
              return acc + 1;
            }
            return acc;
          }, 0);
        },
        minResizeWidth: 10
      },
      {
        show: true,
        id: "consultantsTypeTeam",
        Header: "Team",
        accessor: row => {
          return row.consultants.reduce((acc, cur) => {
            if (cur.type === "TEAM") {
              return acc + 1;
            }
            return acc;
          }, 0);
        },
        minResizeWidth: 10
      },
      {
        show: true,
        id: "consultantsTypeOther",
        Header: "Other",
        accessor: row => {
          return row.consultants.reduce((acc, cur) => {
            if (cur.type === "OTHER") {
              return acc + 1;
            }
            return acc;
          }, 0);
        },
        minResizeWidth: 10
      }
    ]
  },
  {
    Header: "Account info",
    columns: [
      {
        show: true,
        id: "TandEApprover",
        Header: "T&E approver",
        accessor: "TandEApprover",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "accountLead",
        Header: "Account lead",
        accessor: "accountLead",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "saleLead",
        Header: "Sale lead",
        accessor: "saleLead",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "expensePolicy",
        Header: "Expense policy",
        accessor: "expensePolicy",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "notes",
        Header: "Notes",
        accessor: "notes",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "enableOTTimeType",
        Header: "Enable OT time type",
        accessor: "enableOTTimeType",
        sortable: false,
        minResizeWidth: 10
      },
      {
        show: true,
        id: "NDAExecuted",
        Header: "NDA",
        accessor: "NDAExecuted",
        sortable: false,
        minResizeWidth: 10
      },
      {
        show: true,
        id: "iPSAorMSAExecutedd",
        Header: "PSA or MSA",
        accessor: "PSAorMSAExecuted",
        sortable: false,
        minResizeWidth: 10
      },
      {
        show: true,
        id: "SOWorCoorCRExecuted",
        Header: "SOW or Co or CR",
        accessor: "SOWorCoorCRExecuted",
        sortable: false,
        minResizeWidth: 10
      }
    ]
  },
  {
    Header: "Creator",
    columns: [
      {
        show: true,
        id: "creatorOid",
        Header: "oid",
        accessor: "creator.oid",
        minResizeWidth: 10
      },
      {
        show: true,
        sortable: false,
        filterable: true,
        id: "creatorName",
        Header: "Name",
        accessor: "creator.name",
        minResizeWidth: 10
      },
      {
        show: true,
        id: "creatorPhone",
        Header: "phone",
        accessor: "creator.phone",
        minResizeWidth: 10
      }
    ]
  }
];
