import React from "react";
import { Link } from "react-router-dom";

class LinkComponent extends React.Component {
  render() {
    return (
      <Link
        // TODO: check for better solutions
        // TODO: extend functionality for external links
        // ref: https://github.com/ReactTraining/react-router/issues/1147
        className="link-component"
        onClick={() => this.forceUpdate()}
        replace={window.location.pathname === this.props.to ? true : false}
        // note: you can override replace by sending it in props
        {...this.props}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default LinkComponent;
