import * as actions from "../actions/actionTypes";
import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  submitProjectOrChangesSaga,
  fetchProjectsSaga,
  discardProjectOrChangesSaga,
  fetchProjectSaga,
  uploadFileSaga,
  searchAdForUserSaga
} from "./projectSagas";
import { toggleFixedHeaderSaga } from "./authSagas";
import { navigateToDashboard } from "./navigationSagas";
// import { triggerGraphQLErrorSaga } from "./globalSagas";

export function* watchAll() {
  yield takeEvery(actions.TOGGLE_FIXED_HEADER_SAGA, toggleFixedHeaderSaga);
  yield takeEvery(actions.SUBMIT_PROJECT_SAGA, submitProjectOrChangesSaga);
  yield takeEvery(actions.FETCH_PROJECTS_SAGA, fetchProjectsSaga);
  yield takeEvery(actions.NAVIGATE_TO_DASHBOARD_SAGA, navigateToDashboard);
  yield takeEvery(actions.DISCARD_PROJECT_SAGA, discardProjectOrChangesSaga);
  yield takeEvery(actions.FETCH_PROJECT_SAGA, fetchProjectSaga);
  yield takeEvery(actions.UPLOAD_FILE_SAGA, uploadFileSaga);
  // yield takeLatest(actions.GRAPHQL_ERROR_SAGA, triggerGraphQLErrorSaga);
  yield takeLatest(actions.SEARCH_AD_FOR_USER_SAGA, searchAdForUserSaga);
}
