import * as actions from "../actions/actionTypes";
import moment from "moment";

import {
  initialProjectState,
  defaultProjectFormState,
  defaultFormErrorsState
} from "../store/initialState";
// import tableColumns from "../components/views/userDashboard/projectOverview/tableColumns";
import {
  generateDefaultConsultant,
  generateDefaultADUser
  // filterTableColumns
} from "../store/storeFunctions";

export default (state = initialProjectState, action) => {
  // declare variable names that are reused throughout reducers
  let selectedOption = null;

  switch (action.type) {
    /* PROJECT FORM */
    case actions.CHANGE_PROJECT_FIELD:
      let form = { ...state.form };
      switch (action.payload.field) {
        case "billingContactName":
          form.billingContact.name = action.payload.value;
          break;
        case "billingContactEmail":
          form.billingContact.email = action.payload.value;
          break;
        case "billingContactPhone":
          form.billingContact.phone = action.payload.value;
          break;
        default:
          form[action.payload.field] = action.payload.value;
      }

      // validation
      // TODO: rework validation with ajv or similar library
      let formErrors = { ...state.formErrors };
      switch (action.payload.field) {
        case "projectDatesStart":
        case "projectDatesEnd":
          // check if valid value
          if (!action.payload.value || action.payload.value === null) {
            formErrors[action.payload.field] = "Invalid or missing field";
            break;
          }

          // check if its after end date
          if (
            form.projectDatesStart &&
            form.projectDatesEnd &&
            moment(form.projectDatesStart).isAfter(form.projectDatesEnd)
          ) {
            formErrors["projectDatesStart"] = "Cannot be after end date";
            formErrors["projectDatesEnd"] = "Cannot be before start date";
            break;
          }

          if (form.projectDatesStart && form.projectDatesEnd) {
            // all is well
            formErrors["projectDatesStart"] = null;
            formErrors["projectDatesEnd"] = null;
          }
          break;
        case "projectName":
          // project name has to be at least 1 character long
          if (
            !action.payload.value ||
            typeof action.payload.value !== "string" ||
            action.payload.value.length <= 0
          ) {
            formErrors[action.payload.field] = "Invalid or missing field";
            break;
          }

          // there are no errors
          formErrors[action.payload.field] = null;
          break;
        case "projectValue":
          // project value is free text and has to be at least 1 character long
          if (
            !action.payload.value ||
            typeof action.payload.value !== "string" ||
            action.payload.value.length <= 0
          ) {
            formErrors[action.payload.field] = "Invalid or missing field";
            break;
          }

          // there are no errors
          formErrors[action.payload.field] = null;
          break;
        default:
      }

      return {
        ...state,
        formErrors,
        form
      };
    case actions.CHANGE_PROJECT_AUTOCOMPLETE_FIELD:
      selectedOption = action.payload.selectedOption;
      /* if selectedOption is null it means previously selected
       * was cleared/removed, so replace it with a default empty one
       */
      if (!selectedOption) {
        selectedOption = generateDefaultADUser();
      }

      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: selectedOption
        }
      };
    case actions.CHANGE_PROJECT_CONSULTANT_AUTOCOMPLETE_FIELD:
      let consAutocomplete = [...state.form.consultants];

      selectedOption = action.payload.selectedOption;

      /* if selectedOption is null (when cleared)
       * reset user related fields to defaults
       */
      consAutocomplete[action.payload.index] = {
        ...consAutocomplete[action.payload.index],
        oid: selectedOption && selectedOption.oid ? selectedOption.oid : "",
        name: selectedOption && selectedOption.name ? selectedOption.name : "",
        email:
          selectedOption && selectedOption.email ? selectedOption.email : ""
      };

      return {
        ...state,
        form: { ...state.form, consultants: consAutocomplete }
      };
    case actions.CHANGE_PROJECT_CONSULTANT_FIELD:
      let cons = [...state.form.consultants];

      /* if we change consultant type from or to CONTRACTOR type
       * then reset oid/name/email fields, as that type expects different
       * data than other types
       */
      if (
        action.payload.field === "type" &&
        action.payload.value !== cons[action.payload.index].type &&
        (action.payload.value === "CONTRACTOR" ||
          cons[action.payload.index].type === "CONTRACTOR")
      ) {
        cons[action.payload.index] = {
          ...cons[action.payload.index],
          oid: "",
          name: "",
          email: ""
        };
      }

      cons[action.payload.index] = {
        ...cons[action.payload.index],
        [action.payload.field]: action.payload.value
      };

      return { ...state, form: { ...state.form, consultants: cons } };

    // return {
    //   ...state,
    //   ...(state.consultants[action.payload.index] = {
    //     ...state.consultants[action.payload.index],
    //     [action.payload.field]: action.payload.value
    //   })
    // };
    case actions.ADD_PROJECT_CONSULTANT:
      return {
        ...state,
        form: {
          ...state.form,
          consultants: state.form.consultants.concat(
            generateDefaultConsultant()
          )
        }
      };
    case actions.DELETE_PROJECT_CONSULTANT:
      let tempConsultants = [...state.form.consultants];

      if (
        tempConsultants &&
        tempConsultants[action.payload.index] &&
        tempConsultants[action.payload.index].id
      ) {
        // if this consultant is already saved, we have to delete it
        // in database too
        tempConsultants[action.payload.index].toDelete = true;
      } else {
        // delete consultant at specified place
        tempConsultants.splice(action.payload.index, 1);
      }
      // if that was only consultant, effectively 'reset' the array
      if (!tempConsultants || tempConsultants.length === 0) {
        tempConsultants = [generateDefaultConsultant()];
      }

      return {
        ...state,
        form: { ...state.form, consultants: tempConsultants }
      };
    case actions.SUBMIT_OR_UPDATE_PROJECT_SAGA_SUCCESS:
      return { ...initialProjectState };
    // case actions.SUBMIT_OR_UPDATE_PROJECT_SAGA_FAILURE:
    //   return { ...state };
    /* PROJECT OVERVIEW */
    case actions.FETCH_PROJECTS_SAGA:
      return { ...state, loadingTableData: true };
    case actions.FETCH_PROJECTS_SAGA_SUCCESS:
      const projectsData = action.payload.data;
      const projectsPageCount = action.payload.projectsPageCount;
      return {
        ...state,
        tableData: projectsData,
        projectsPageCount,
        loadingTableData: false
      };
    case actions.FETCH_PROJECTS_SAGA_FAILURE:
      return { ...state, loadingTableData: false };
    case actions.FETCH_PROJECT_SAGA_SUCCESS:
      const projectData = action.payload.data;
      return {
        ...state,
        formErrors: defaultFormErrorsState(),
        form: projectData,
        loadingForm: false
      };
    case actions.FETCH_PROJECT_SAGA_FAILURE:
      return { ...state };
    case actions.RESET_LOADING_FORM_STATUS:
      return { ...state, loadingForm: true };
    case actions.LOAD_NEW_PROJECT_FORM:
      return {
        ...state,
        formErrors: defaultFormErrorsState(),
        form: defaultProjectFormState(),
        loadingForm: false
      };
    case actions.CLEAN_PROJECT_FORM:
      return {
        ...state,
        formErrors: defaultFormErrorsState(),
        form: defaultProjectFormState(),
        loadingForm: true
      };
    /* file upload */
    case actions.UPLOAD_FILE_SAGA:
      return { ...state, uploadsInProgress: state.uploadsInProgress + 1 };
    case actions.UPLOAD_FILE_SAGA_SUCCESS:
      // set new file on beginning of the array, so it can be easily visible
      const documents = [{ ...action.payload }].concat(state.form.documents);
      return {
        ...state,
        uploadsInProgress: state.uploadsInProgress - 1,
        form: { ...state.form, documents }
      };
    case actions.UPLOAD_FILE_SAGA_FAILURE:
      return { ...state, uploadsInProgress: state.uploadsInProgress - 1 };
    case actions.REMOVE_FILE_FROM_PROJECT:
      let tempDocuments = [...state.form.documents];

      if (
        tempDocuments &&
        tempDocuments[action.payload.index] &&
        tempDocuments[action.payload.index].id
      ) {
        // if this document is already saved, we have to delete it
        // in database too
        tempDocuments[action.payload.index].toDelete = true;
      } else {
        // delete document at specified place
        tempDocuments.splice(action.payload.index, 1);
      }

      return { ...state, form: { ...state.form, documents: tempDocuments } };
    case actions.SEARCH_AD_FOR_USER_SUCCESS:
      return { ...state, autocompleteOptions: action.payload.data };
    case actions.SEARCH_AD_FOR_USER_FAILURE:
      return { ...state };
    // case actions.CHANGE_DISPLAYED_COLUMNS:
    //   const columnsToShow = action.payload.columnsToShow;
    //   const columns = filterTableColumns(tableColumns, columnsToShow);
    //   return { ...state, columns, columnsToShow };
    default:
      return state;
  }
};
