import ApolloClient from "apollo-boost";

import { MSAL_IDTOKEN_KEY } from "../utils/constants";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
  // prevent default error handler from running
  onError: () => {},
  request: operation => {
    // set auth header before each request
    const headers = {
      Authorization: "Bearer " + localStorage.getItem(MSAL_IDTOKEN_KEY)
      // Authorization: "Bearer " + process.env.REACT_APP_TEMP_TOKEN
    };

    operation.setContext({ headers });
  }
});

export default client;
