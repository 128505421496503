import gql from "graphql-tag";

export const UpdateProjectMutation = gql`
  mutation(
    $id: ID!
    $clientName: String
    $clientAddress: String
    $invoiceTermsAndBillingInstructions: String
    $projectName: String
    $projectDatesStart: DateTime
    $projectDatesEnd: DateTime
    $enableExpenses: yesAndNo
    $projectValue: String
    $PONumber: String
    $TandEApprover: ADUserUpdateDataInput
    $accountLead: ADUserUpdateDataInput
    $saleLead: ADUserUpdateDataInput
    $enableOTTimeType: yesAndNo
    $expensePolicy: String
    $notes: String
    $PSAorMSAExecuted: yesAndNo
    $NDAExecuted: yesAndNo
    $SOWorCoorCRExecuted: yesAndNo
    $newConsultants: [ConsultantCreateInput!]
    $updateConsultants: [ConsultantUpdateWithWhereUniqueNestedInput!]
    $deleteConsultants: [ConsultantWhereUniqueInput!]
    $billingContact: BillingContactUpdateDataInput
    $newDocuments: [DocumentCreateInput!]
    $updateDocuments: [DocumentUpdateWithWhereUniqueNestedInput!]
    $deleteDocuments: [DocumentWhereUniqueInput!]
  ) {
    updateProject(
      where: { id: $id }
      data: {
        clientName: $clientName
        clientAddress: $clientAddress
        invoiceTermsAndBillingInstructions: $invoiceTermsAndBillingInstructions
        projectName: $projectName
        projectDatesStart: $projectDatesStart
        projectDatesEnd: $projectDatesEnd
        enableExpenses: $enableExpenses
        projectValue: $projectValue
        PONumber: $PONumber
        billingContact: { update: $billingContact }
        consultants: {
          create: $newConsultants
          update: $updateConsultants
          delete: $deleteConsultants
        }
        documents: {
          create: $newDocuments
          update: $updateDocuments
          delete: $deleteDocuments
        }
        TandEApprover: { update: $TandEApprover }
        accountLead: { update: $accountLead }
        saleLead: { update: $saleLead }
        enableOTTimeType: $enableOTTimeType
        expensePolicy: $expensePolicy
        notes: $notes
        PSAorMSAExecuted: $PSAorMSAExecuted
        NDAExecuted: $NDAExecuted
        SOWorCoorCRExecuted: $SOWorCoorCRExecuted
      }
    ) {
      id
    }
  }
`;
