import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

import "./scss/Main.scss";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import asyncComponent from "./utils/AsyncComponent";

import { configureStore, history } from "./store/configurationStore";

import { requestedPageOnOpen } from "./actions/authActions";

import { isLoggedIn } from "./config/msalConfiguration";

import LoginPage from "./components/views/login/LoginPage";

export const store = configureStore();

// lazy load route components
const Login = asyncComponent(() => import("./components/views/login/login"));
const ViewRoutes = asyncComponent(() =>
  import("./components/views/ViewRoutes")
);

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() === true ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const theme = createMuiTheme({
  palette: { primary: { main: "#003768" } },
  typography: { useNextVariants: true }
});

class App extends Component {
  // when using material theme, we disable deprecated variants in passed theme
  constructor(props) {
    super(props);

    // dispatch action containing pathname user requested upon landing on the site
    store.dispatch(requestedPageOnOpen(window.location.pathname));
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <>
            <Helmet>
              <title>Project Setup</title>
            </Helmet>
            <MuiThemeProvider theme={theme}>
              <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/" exact component={LoginPage} />
                <ProtectedRoute path="*" component={ViewRoutes} />
              </Switch>
            </MuiThemeProvider>
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
