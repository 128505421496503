import * as actions from "../actions/actionTypes";
import { put } from "redux-saga/effects";

export function* logoutSaga(action) {
  // yield console.log(action);
}

export function* toggleFixedHeaderSaga(action) {
  // change locally right away
  yield put({ type: actions.TOGGLE_FIXED_HEADER, payload: action.payload });

  // send change to server
  yield sleep(3000);
}

// sleep for testing purposes
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
