/* router - navigation actions */
export const NAVIGATE_TO_DASHBOARD_SAGA = "NAVIGATE_TO_DASHBOARD_SAGA";

/* user actions */
export const TOGGLE_FIXED_HEADER_SAGA = "TOGGLE_FIXED_HEADER_SAGA";
export const TOGGLE_FIXED_HEADER = "TOGGLE_FIXED_HEADER";

/* auth actions*/
export const AZURE_TOKEN_SUCCESS = "AZURE_TOKEN_SUCCESS";
export const AZURE_TOKEN_FAILURE = "AZURE_TOKEN_FAILURE";

export const REQUESTED_PAGE_ON_OPEN = "REQUESTED_PAGE_ON_OPEN";
export const CLEAR_REQUESTED_PAGE_ON_OPEN = "CLEAR_REQUESTED_PAGE_ON_OPEN";

/* project form actions */
export const CLOSE_PROJECT_SUCCESS_SNACKBAR = "CLOSE_PROJECT_SUCCESS_SNACKBAR";

export const SEARCH_AD_FOR_USER_SAGA = "SEARCH_AD_FOR_USER_SAGA";
export const SEARCH_AD_FOR_USER_SUCCESS = "SEARCH_AD_FOR_USER_SUCCESS";
export const SEARCH_AD_FOR_USER_FAILURE = "SEARCH_AD_FOR_USER_FAILURE";

export const CHANGE_PROJECT_FIELD = "CHANGE_PROJECT_FIELD";
export const CHANGE_PROJECT_AUTOCOMPLETE_FIELD =
  "CHANGE_PROJECT_AUTOCOMPLETE_FIELD";
export const CHANGE_PROJECT_CONSULTANT_AUTOCOMPLETE_FIELD =
  "CHANGE_PROJECT_CONSULTANT_AUTOCOMPLETE_FIELD";
export const CHANGE_PROJECT_CONSULTANT_FIELD =
  "CHANGE_PROJECT_CONSULTANT_FIELD";
export const ADD_PROJECT_CONSULTANT = "ADD_PROJECT_CONSULTANT";
export const DELETE_PROJECT_CONSULTANT = "DELETE_PROJECT_CONSULTANT";

export const SUBMIT_PROJECT_SAGA = "SUBMIT_PROJECT_SAGA";
export const SUBMIT_OR_UPDATE_PROJECT_SAGA_SUCCESS =
  "SUBMIT_OR_UPDATE_PROJECT_SAGA_SUCCESS";
export const SUBMIT_OR_UPDATE_PROJECT_SAGA_FAILURE =
  "SUBMIT_OR_UPDATE_PROJECT_SAGA_FAILURE";

// file upload
export const UPLOAD_FILE_SAGA = "UPLOAD_FILE_SAGA";
export const UPLOAD_FILE_SAGA_SUCCESS = "UPLOAD_FILE_SAGA_SUCCESS";
export const UPLOAD_FILE_SAGA_FAILURE = "UPLOAD_FILE_SAGA_FAILURE";

// file removal
export const REMOVE_FILE_FROM_PROJECT = "REMOVE_FILE_FROM_PROJECT";

export const DISCARD_PROJECT_SAGA = "DISCARD_PROJECT_SAGA";
export const LOAD_NEW_PROJECT_FORM = "LOAD_NEW_PROJECT_FORM";
export const CLEAN_PROJECT_FORM = "CLEAN_PROJECT_FORM";
export const RESET_LOADING_FORM_STATUS = "RESET_LOADING_FORM_STATUS";

export const FETCH_PROJECT_SAGA = "FETCH_PROJECT_SAGA";
export const FETCH_PROJECT_SAGA_SUCCESS = "FETCH_PROJECT_SAGA_SUCCESS";
export const FETCH_PROJECT_SAGA_FAILURE = "FETCH_PROJECT_SAGA_FAILURE";

/* project overview actions */
export const FETCH_PROJECTS_SAGA = "FETCH_PROJECTS_SAGA";
export const FETCH_PROJECTS_SAGA_SUCCESS = "FETCH_PROJECTS_SAGA_SUCCESS";
export const FETCH_PROJECTS_SAGA_FAILURE = "FETCH_PROJECTS_SAGA_FAILURE";

// export const CHANGE_DISPLAYED_COLUMNS = "CHANGE_DISPLAYED_COLUMNS";

/* global */
export const CLOSE_GLOBAL_SNACKBAR = "CLOSE_GLOBAL_SNACKBAR";
export const OPEN_GLOBAL_SNACKBAR = "OPEN_GLOBAL_SNACKBAR";

export const GRAPHQL_ERROR_SAGA = "GRAPHQL_ERROR_SAGA";
