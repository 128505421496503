// msal
export const MSAL_IDTOKEN_KEY = "msal.idtoken";

// app
export const DEBOUNCE_INPUT_DELAY = 500;
export const DEBOUNCE_FUNCTION_DELAY = 200;
export const DEBOUNCE_AUTOCOMPLETE_SUGGESTIONS_DELAY = 350;
export const DEBOUNCE_TABLE_FILTER_DELAY = 400;

export const DEFAULT_AUTOCOMPLETE_FETCH_LIMIT = 5;

export const DEFAULT_PAGES_COUNT = 1;

// routes
export const ROUTE_TO_LANDING_PAGE = "/";
export const ROUTE_TO_LOGIN = "/login";
export const ROUTE_TO_DASHBOARD = "/dashboard";
export const ROUTE_TO_NEW_PROJECT = "/project/create";

// snackbar messages
export const FILE_SIZE_TOO_LARGE_MESSAGE = "File size too large!";
export const UNABLE_TO_FIND_PROJECT_MESSAGE =
  "Could not find specified project";
