import * as actions from "../actions/actionTypes";

import { getUser } from "../config/msalConfiguration";
import { initialAuthState } from "../store/initialState";
import { getNestedObject } from "../utils/functions";

export default (state = initialAuthState, action) => {
  switch (action.type) {
    case actions.AZURE_TOKEN_SUCCESS:
      const user = getUser();
      return {
        ...state,
        user,
        token: action.payload.token,
        isAuthenticated: true,
        err: null
      };
    case actions.AZURE_TOKEN_FAILURE:
      return {
        ...state,
        user: {},
        token: null,
        isAuthenticated: false,
        err: action.payload.err
      };
    case actions.REQUESTED_PAGE_ON_OPEN:
      // do not execute action if it's not first page load
      if (state.isInitialRequestedPageStored) {
        return { ...state };
      }

      // continue otherwise
      let requestedPage = getNestedObject(action, ["payload", "pathname"]);

      if (!requestedPage || !requestedPage.includes("project")) {
        requestedPage = "/dashboard";
      }

      return {
        ...state,
        requestedPage,
        isFirstPageLoadDone: true
      };
    case actions.CLEAR_REQUESTED_PAGE_ON_OPEN:
      return {
        ...state,
        requestedPage: null
      };
    default:
      return state;
  }
};
