import gql from "graphql-tag";

export const GetProjectsQuery = gql`
  query(
    $where: ProjectWhereInput
    $orderBy: ProjectOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    count: projectsConnection(where: $where) {
      aggregate {
        count
      }
    }
    projectsConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          clientName
          # clientAddress
          # invoiceTermsAndBillingInstructions
          projectName
          projectDatesStart
          projectDatesEnd
          creator {
            oid
            name
            email
          }
          # enableExpenses
          projectValue
          # PONumber
          # billingContact {
          #   name
          #   email
          #   phone
          # }
          # consultants {
          #   id
          #   name
          #   type
          # }
          # TandEApprover {
          #   oid
          #   name
          #   email
          # }
          # accountLead{
          #   oid
          #   name
          #   email
          # }
          # saleLead{
          #   oid
          #   name
          #   email
          # }
          # enableOTTimeType
          # expensePolicy
          # notes
          # PSAorMSAExecuted
          # NDAExecuted
          # SOWorCoorCRExecuted}
        }
      }
    }
  }
`;

// export const GetProjectsQuery = gql`
//   query(
//     $where: ProjectWhereInput
//     $orderBy: ProjectOrderByInput
//     $skip: Int
//     $after: String
//     $before: String
//     $first: Int
//     $last: Int
//   ) {
//     projects(
//       where: $where
//       orderBy: $orderBy
//       skip: $skip
//       after: $after
//       before: $before
//       first: $first
//       last: $last
//     ) {
//       id
//       clientName
//       # clientAddress
//       # invoiceTermsAndBillingInstructions
//       projectName
//       projectDatesStart
//       projectDatesEnd
//       creator {
//         oid
//         name
//         email
//       }
//       # enableExpenses
//       projectValue
//       # PONumber
//       # billingContact {
//       #   name
//       #   email
//       #   phone
//       # }
//       # consultants {
//       #   id
//       #   name
//       #   type
//       # }
//       # TandEApprover {
//       #   oid
//       #   name
//       #   email
//       # }
//       # accountLead{
//       #   oid
//       #   name
//       #   email
//       # }
//       # saleLead{
//       #   oid
//       #   name
//       #   email
//       # }
//       # enableOTTimeType
//       # expensePolicy
//       # notes
//       # PSAorMSAExecuted
//       # NDAExecuted
//       # SOWorCoorCRExecuted
//     }
//   }
// `;
