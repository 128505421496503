import gql from "graphql-tag";

export const GetProjectQuery = gql`
  query($id: ID) {
    project(where: { id: $id }) {
      id
      clientName
      clientAddress
      invoiceTermsAndBillingInstructions
      projectName
      projectDatesStart
      projectDatesEnd
      creator {
        oid
        name
        email
      }
      enableExpenses
      projectValue
      PONumber
      billingContact {
        name
        email
        phone
      }
      consultants {
        id
        type
        oid
        name
        email
        startDate
        endDate
        hourlyRate
      }
      documents {
        id
        name
        key
        type
        contentType
        bucket
        resourceUrl
        signedResourceUrl
      }
      TandEApprover {
        oid
        name
        email
      }
      accountLead {
        oid
        name
        email
      }
      saleLead {
        oid
        name
        email
      }
      enableOTTimeType
      expensePolicy
      notes
      PSAorMSAExecuted
      NDAExecuted
      SOWorCoorCRExecuted
    }
  }
`;
