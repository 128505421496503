import React, { PureComponent } from "react";
import moment from "moment";

import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";

import MomentUtils from "@date-io/moment";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";

// import TimePicker from "material-ui-pickers/TimePicker";
import DatePicker from "material-ui-pickers/DatePicker";
// import DateTimePicker from "material-ui-pickers/DateTimePicker";

class UTCUtils extends MomentUtils {
  format(value, formatString) {
    return moment(value)
      .utc()
      .format(formatString);
  }
}

/*
 * PROPS:
 * defaultToday - defaults date to today when mounting component
 * NOT USED - TimeOnly - use TimePicker
 * NOT USED - DateTime - use DateTime Picker
 * USFormat - format dates as "MM/D/YYYY" instead of "DD.MM.YYYY", and times as am/pm instead of 24hrs
 */
class CustomDatePicker extends PureComponent {
  state = {
    selectedDate: null
  };

  componentDidMount() {
    const { defaultToday } = this.props;
    defaultToday && this.setState({ selectedDate: new Date() });
  }

  handleDateChange = date => {
    const startOfDate = date ? moment(moment.utc(date)).startOf("day") : null;

    typeof this.props.onChange === "function" &&
      this.props.onChange(startOfDate);
    this.setState({ selectedDate: startOfDate });
  };

  render() {
    const { selectedDate } = this.state;
    const {
      USFormat,
      label,
      shortWidth,
      defaultValue,
      clearable,
      disableUnderline,
      ...rest
    } = this.props;

    // we use same props on all components
    const pickerProps = {
      value: selectedDate || defaultValue || null,
      clearable: clearable === true,
      autoOk: true,
      InputProps: { disableUnderline: !!disableUnderline },
      ...rest,
      onChange: this.handleDateChange
    };

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={UTCUtils} moment={moment}>
          {/* {TimeOnly ? (
            <TimePicker
              ampm={Boolean(USFormat)}
              className="datepicker-dateonly-timeonly-set-width"
              {...pickerProps}
            />
          ) : DateTime ? (
            <DateTimePicker
              ampm={Boolean(USFormat)}
              leftArrowIcon={<NavigateBefore />}
              rightArrowIcon={<NavigateNext />}
              {...pickerProps}
            />
          ) : ( */}
          <DatePicker
            label={label}
            className={
              shortWidth
                ? "datepicker-dateonly-short-width"
                : "datepicker-dateonly-timeonly-set-width"
            }
            format={USFormat ? "MM/DD/YYYY" : "DD.MM.YYYY"}
            leftArrowIcon={<NavigateBefore />}
            rightArrowIcon={<NavigateNext />}
            {...pickerProps}
          />
          {/* )} */}
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }
}

export default CustomDatePicker;
