import * as actions from "./actionTypes";

export const AzureTokenSuccess = token => {
  return {
    type: actions.AZURE_TOKEN_SUCCESS,
    payload: { token }
  };
};

export const AzureTokenFailure = err => {
  return { type: actions.AZURE_TOKEN_FAILURE, payload: { err } };
};

export const requestedPageOnOpen = pathname => {
  return { type: actions.REQUESTED_PAGE_ON_OPEN, payload: { pathname } };
};

export const clearRequestedPageOnOpen = () => {
  return { type: actions.CLEAR_REQUESTED_PAGE_ON_OPEN, payload: null };
};
