export const generateDefaultConsultant = () => ({
  key: Date.now(),
  type: "CONSULTANT",
  oid: "",
  name: "",
  email: "",
  startDate: null,
  endDate: null,
  hourlyRate: "0"
});

export const generateDefaultADUser = () => ({
  oid: "",
  name: "",
  email: ""
});

/*
 * filters tableColumns (where all possible columns are)
 * to only show the ones we want to display
 */
export const filterTableColumns = (tableColumns, columnsToShow) => {
  let columns = tableColumns.filter(row => {
    if (row.columns) {
      return true;
    } else {
      if (row.id && columnsToShow.includes(row.id)) {
        return true;
      } else {
        return false;
      }
    }
  });

  columns = columns.map(row => {
    if (row.columns) {
      // it is just a header group, go into columns below
      return {
        ...row,
        columns: row.columns.filter(subRow => {
          if (subRow.id && columnsToShow.includes(subRow.id)) {
            return true;
          } else {
            return false;
          }
        })
      };
    }
    return row;
  });
  return columns;
};
