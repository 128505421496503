import gql from "graphql-tag";

export const adUserSearch = gql`
  query($contains: String, $limit: Int) {
    adUserSearch(contains: $contains, limit: $limit) {
      oid
      email
      name
      # group
    }
  }
`;
