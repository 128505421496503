import { getNestedObject } from "./functions";

import {
  CODE_UNAUTHORIZED,
  KEY_INVALID_AUTHENTICATION_TOKEN,
  CODE_SERVER_ERROR
} from "./errorConstants";
import { ROUTE_TO_LOGIN } from "./constants";

// handle saga errors
// determine whether to display snackbar and/or redirect
export const errorHandler = err => {
  const graphQLErrors = getNestedObject(err, ["graphQLErrors"]);
  // const networkError = getNestedObject(err, ["networkError"]);

  let openSnackbar = false;
  let redirect = false;
  let messageSnackbar = "";
  let redirectTo = "";

  if (graphQLErrors) {
    graphQLErrors.forEach(element => {
      switch (element.code) {
        case CODE_UNAUTHORIZED:
          switch (element.key) {
            case KEY_INVALID_AUTHENTICATION_TOKEN:
              break;
            default:
              // always redirect to login when unauthorized
              redirect = true;
              redirectTo = ROUTE_TO_LOGIN;
          }
          break;
        case CODE_SERVER_ERROR:
          openSnackbar = true;
          messageSnackbar = "Something went wrong";
          break;
        default:
      }
    });
  }

  // handle network errors
  // if (networkError) {
  // }

  return [openSnackbar, messageSnackbar, redirect, redirectTo];
};
