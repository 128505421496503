import gql from "graphql-tag";

export const CreateProjectMutation = gql`
  mutation(
    $clientName: String
    $clientAddress: String
    $invoiceTermsAndBillingInstructions: String
    $projectName: String!
    $projectDatesStart: DateTime!
    $projectDatesEnd: DateTime!
    $enableExpenses: yesAndNo!
    $projectValue: String!
    $PONumber: String
    $TandEApprover: ADUserCreateInput
    $accountLead: ADUserCreateInput
    $saleLead: ADUserCreateInput
    $enableOTTimeType: yesAndNo!
    $expensePolicy: String
    $notes: String
    $PSAorMSAExecuted: yesAndNo!
    $NDAExecuted: yesAndNo!
    $SOWorCoorCRExecuted: yesAndNo!
    $consultants: [ConsultantCreateInput!]
    $billingContact: BillingContactCreateInput!
    $documents: [DocumentCreateInput!]
  ) {
    createProject(
      data: {
        clientName: $clientName
        clientAddress: $clientAddress
        invoiceTermsAndBillingInstructions: $invoiceTermsAndBillingInstructions
        projectName: $projectName
        projectDatesStart: $projectDatesStart
        projectDatesEnd: $projectDatesEnd
        enableExpenses: $enableExpenses
        projectValue: $projectValue
        PONumber: $PONumber
        billingContact: { create: $billingContact }
        consultants: { create: $consultants }
        documents: { create: $documents }
        TandEApprover: { create: $TandEApprover }
        accountLead: { create: $accountLead }
        saleLead: { create: $saleLead }
        enableOTTimeType: $enableOTTimeType
        expensePolicy: $expensePolicy
        notes: $notes
        PSAorMSAExecuted: $PSAorMSAExecuted
        NDAExecuted: $NDAExecuted
        SOWorCoorCRExecuted: $SOWorCoorCRExecuted
      }
    ) {
      id
      clientName
    }
  }
`;
