// boolean to yesAndNo graphql enum
export const transformEnumsToBooleans = variables => {
  variables &&
    Object.keys(variables).forEach(key => {
      if (variables[key] === "YES") {
        variables[key] = true;
      }

      if (variables[key] === "NO") {
        variables[key] = false;
      }
    });
};

export const transformBooleansToEnums = variables => {
  variables &&
    Object.keys(variables).forEach(key => {
      if (typeof variables[key] === "boolean") {
        if (variables[key]) {
          variables[key] = "YES";
        } else {
          variables[key] = "NO";
        }
      }
    });
};

// transform object to send when creating project
export const transformCreateProject = vars => {
  let variables = { ...vars };

  // boolean to yesAndNo graphql enum
  transformBooleansToEnums(variables);

  // filter out any consultants that don't have any of
  // name, rate or dates set
  let preparedConsultants =
    variables.consultants &&
    variables.consultants.filter(
      row =>
        row.name !== "" ||
        row.hourlyRate !== "0" ||
        row.startDate !== null ||
        row.endDate !== null
    );

  preparedConsultants =
    preparedConsultants &&
    preparedConsultants.map(row => {
      // return only fields we want to send
      return {
        oid: row.oid,
        name: row.name,
        email: row.email,
        type: row.type,
        startDate: row.startDate,
        endDate: row.endDate,
        hourlyRate: row.hourlyRate
      };
    });

  // TODO: handle this in a better way?
  delete variables.__typename;
  variables.billingContact && delete variables.billingContact.__typename;
  variables.TandEApprover && delete variables.TandEApprover.__typename;
  variables.accountLead && delete variables.accountLead.__typename;
  variables.saleLead && delete variables.saleLead.__typename;

  return { ...variables, consultants: preparedConsultants };
};

export const transformUpdateProject = vars => {
  let variables = { ...vars };

  // boolean to yesAndNo graphql enum
  transformBooleansToEnums(variables);

  // prepare new consultants
  let newConsultants =
    variables &&
    variables.consultants &&
    variables.consultants.filter(
      row =>
        !row.id &&
        (row.name !== "" ||
          row.hourlyRate !== "0" ||
          row.startDate !== null ||
          row.endDate !== null)
    );

  newConsultants = newConsultants.map(row => {
    return {
      oid: row.oid,
      name: row.name,
      email: row.email,
      type: row.type,
      startDate: row.startDate,
      endDate: row.endDate,
      hourlyRate: row.hourlyRate
    };
  });

  // prepare existing consultants to update
  let updateConsultants =
    variables &&
    variables.consultants &&
    variables.consultants.filter(row => row.id && !row.toDelete);

  updateConsultants = updateConsultants.map(row => {
    return {
      where: { id: row.id },
      data: {
        oid: row.oid,
        name: row.name,
        email: row.email,
        type: row.type,
        startDate: row.startDate,
        endDate: row.endDate,
        hourlyRate: row.hourlyRate
      }
    };
  });

  // prepare existing consultants we want to delete
  let deleteConsultants =
    variables &&
    variables.consultants &&
    variables.consultants.filter(row => row.id && row.toDelete);

  deleteConsultants = deleteConsultants.map(row => {
    return { id: row.id };
  });

  // prepare new documents
  let newDocuments =
    variables &&
    variables.documents &&
    variables.documents.filter(row => !row.id);

  // update documents
  let updateDocuments =
    variables &&
    variables.documents &&
    variables.documents.filter(row => row.id && !row.toDelete);

  updateDocuments = updateDocuments.map(row => {
    return {
      where: { id: row.id },
      data: {
        name: row.name,
        contentType: row.contentType,
        type: row.type,
        key: row.key
      }
    };
  });

  // prepare existing documents we want to delete
  let deleteDocuments =
    variables &&
    variables.documents &&
    variables.documents.filter(row => row.id && row.toDelete);

  deleteDocuments = deleteDocuments.map(row => {
    return { id: row.id };
  });

  // TODO: handle this in a better way?
  delete variables.__typename;
  variables.billingContact && delete variables.billingContact.__typename;
  variables.TandEApprover && delete variables.TandEApprover.__typename;
  variables.accountLead && delete variables.accountLead.__typename;
  variables.saleLead && delete variables.saleLead.__typename;
  delete variables.consultants;
  delete variables.documents;

  return {
    ...variables,
    newConsultants,
    updateConsultants,
    deleteConsultants,
    newDocuments,
    updateDocuments,
    deleteDocuments
  };
};

export const transformAutocompleteUserObject = obj => {
  if (!obj || !obj.oid) {
    return null;
  }

  return {
    value: obj.oid,
    label: obj.name + (obj.email ? " " + obj.email : "")
  };
};
