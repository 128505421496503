import {
  generateDefaultConsultant,
  filterTableColumns,
  generateDefaultADUser
} from "./storeFunctions";
import tableColumns from "../components/views/userDashboard/projectOverview/tableColumns";

/* PROJECT STATES */
const defaultColumnsToShow = [
  // "id",
  "edit",
  "clientName",
  "projectName",
  "projectDatesStart",
  "projectDatesEnd",
  // "projectValue",
  "creatorName"
  // "invoiceTermsAndBillingInstructions"
];

export const defaultProjectFormState = () => ({
  id: "",
  clientName: "",
  clientAddress: "",
  projectName: "",
  projectDatesStart: null,
  projectDatesEnd: null,
  invoiceTermsAndBillingInstructions: "",
  enableExpenses: false,
  projectValue: "",
  PONumber: "",
  billingContact: {
    name: "",
    email: "",
    phone: ""
  },
  documents: [],
  TandEApprover: generateDefaultADUser(),
  accountLead: generateDefaultADUser(),
  saleLead: generateDefaultADUser(),
  expensePolicy: "",
  notes: "",
  enableOTTimeType: false,
  PSAorMSAExecuted: false,
  NDAExecuted: false,
  SOWorCoorCRExecuted: false,
  consultants: [generateDefaultConsultant()]
});

export const defaultFormErrorsState = () => ({});

export const initialProjectState = {
  tableData: [],
  columns: filterTableColumns(tableColumns, defaultColumnsToShow),
  columnsToShow: defaultColumnsToShow,
  loadingForm: true,
  uploadsInProgress: 0,
  autocompleteOptions: [],
  loadingTableData: false,
  projectsPageCount: null,
  formErrors: defaultFormErrorsState(),
  form: defaultProjectFormState()
};

/* GLOBAL STATES */
export const initialGlobalState = {
  isSnackbarOpen: false,
  snackbarMessage: "",
  fixedHeader: true
};

/* AUTH STATES */
export const initialAuthState = {
  user: {},
  accessLevel: 0,
  token: null,
  err: null,
  isAuthenticated: false,
  requestedPage: null,
  isInitialRequestedPageStored: false
};
