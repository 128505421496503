import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import AuthReducer from "./reducer_auth";
import ProjectReducer from "./reducer_project";
import GlobalReducer from "./reducer_global";

const rootReducer = history =>
  combineReducers({
    auth: AuthReducer,
    project: ProjectReducer,
    global: GlobalReducer,
    router: connectRouter(history)
  });

export default rootReducer;
