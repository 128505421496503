import * as actions from "../actions/actionTypes";
import { initialGlobalState } from "../store/initialState";

export default (state = initialGlobalState, action) => {
  switch (action.type) {
    case actions.CLOSE_GLOBAL_SNACKBAR:
      return { ...state, isSnackbarOpen: false };
    case actions.OPEN_GLOBAL_SNACKBAR:
      return {
        ...state,
        isSnackbarOpen: true,
        snackbarMessage: action.payload.message
      };
    case actions.TOGGLE_FIXED_HEADER:
      return { ...state, fixedHeader: !state.fixedHeader };
    default:
      return state;
  }
};
